import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import piniaStore from './store';
import * as qiniu from 'qiniu-js'
import 'ant-design-vue/dist/antd.css';
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import '/@/styles/index.less';
import '/@/styles/reset.less';
import 'uno.css';
import { message } from 'ant-design-vue';
import moment from 'moment'
import {vue3Track} from 'zsw-event-track'
moment.locale("zh-cn");
// 支持SVG
import 'virtual:svg-icons-register';


//引入iconfont
import '/@/assets/iconfont/iconfont.css'

const app = createApp(App);
app.config.globalProperties.$Message = message
app.config.globalProperties.$moment = moment
app.config.globalProperties.$qiniu = qiniu


app.use(router);
app.use(piniaStore);
app.use(vue3Track, {
  maxCache: 20,
  apiUrl: import.meta.env.MODE === 'production' ? '//b.izsw.net/api/platform/trackEvent/save'  :'http://b.temp.izsw.net/api/platform/trackEvent/save',
})
app.mount('#app');
