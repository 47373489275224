<template>
    <div class="navigation">
        <div class="content">
            <div class="left">
                <img src="https://zsw-bimface-res.izsw.net/img/bim-community/LOGObim-community.png" @click="refresh" alt="">
                <span>模型社区</span>
                <span v-if="userinfo" @click="gotoPage">工作台</span>
            </div>
            <div class="right">
                <template v-if="!userinfo">
                    <span @click="loginModel" v-track="'社区-首页-登录'">登录</span>
                    <span @click="register" v-track="'社区-首页-注册'">注册</span>
                </template>
                <a-popover placement="bottomLeft" overlay-class-name="main-top-user-feedback-popover" >
                    <template #content>
                        <div class="my-info route-item" @click="gotoPage" style="cursor: pointer;">
                            <div class="user-route" >
                                工作台
                            </div>
                        </div>
                        <div class="login-out route-item" @click="logoutModel" style="cursor: pointer;">
                            <div class="user-route" >退出登录</div>
                        </div>
                    </template>
                    <template v-if="userinfo">
                        <div class="user">
                            <img style="width: 30px ;height: 30px; border-radius: 50%;" :src="userinfo.profilePhoto" alt="">
                            {{ userinfo.nickname }}
                        </div>
                    </template>
                </a-popover>
                <login v-if="showLoginModal" @closeLoginModal="handleCloseLoginModal" @loginSuccess="handleLoginSuccess">
                </login>
            </div>
        </div>
    </div>
</template>
<script setup>
import login from '/@/components/login.vue';
import { notification } from 'ant-design-vue';
import { useUserStore } from '/@/store';
import { onMounted } from 'vue';
import { useRouter } from "vue-router";
import { useTracking } from 'zsw-event-track'
const { setGlobalContext } = useTracking()
const router = useRouter();
const userStore = useUserStore();
const refresh=()=>{
    window.location.reload();
    // if (router.currentRoute.value.name=="home") {
        // window.location.reload();
    // }else{
    //     router.push({ path: '/home' })
    // }
    console.log(router.currentRoute.value.name);
}
// const userNickname=ref()
// 用户信息
let  userinfo = ref(JSON.parse(localStorage.getItem("USER__INFO__")));
setGlobalContext('userId', userinfo.value?.userId)
// 登录框显影
const showLoginModal = ref(false);

// 登录成功后显示用户昵称
const handleLoginSuccess = (userInfo) => {
    userinfo.value = userInfo
    // console.log(userinfo.value);
}
// 登录成功后关闭登录框
const loginModel = async () => {
    showLoginModal.value = true
}
const logoutModel = async () => {
    userinfo.value = ""
    try {
        const logoutInfo = await userStore.logout();
        if (logoutInfo.code == 200) {
            notification.success({
                message: '已退出',
                description: ``,
                duration: 3,
            });
        }
    } finally {
    }
}
const handleCloseLoginModal = () => {
    showLoginModal.value = false
}
const register = () => {
    window.location.href = 'https://vdp.izsw.net/invite'
}
const gotoPage = () => {
    const currentHost = window.location.origin;
    const ipWithCommunity = `${currentHost}/admin`;
    window.location.href = ipWithCommunity
    // window.open(ipWithCommunity, '_blank');
}
</script>
<style scoped lang="less">
.navigation {
    user-select:none;
    width: 100%;
    height: 60px;
    position: relative;
    top: 0;
    border-radius: 0px 0px 8px 8px;
    background: rgba(48, 51, 70, 0.7);
    border: 1px solid #3E3E3E;
    z-index: 999;
    position: relative;
    position: fixed;
    top: 0px;
    width: 100%;
    height: 60px;
    position: relative;
    top: 0;
    background-color: rgba(48, 51, 70, 0.7);
    border-radius: 0px 0px 8px 8px;
    border: 1px solid #3E3E3E;
    z-index: 1;
    position: fixed;
    top: 0px;

    .content {
        width: 1200px;
        height: 60px;
        margin: 0 auto;
        margin-top: -1px;
        display: flex;
        justify-content: space-between;

        .left {
            width: 45%;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            img{
                cursor: pointer;
            }
            span:nth-child(2) {
                display: inline-block;
                width: 100px;
                height: 30px;
                background: linear-gradient(135deg, #16A5E6 0%, #BB30C5 100%);
                border-radius: 8px 8px 8px 8px;
                font-size: 14px;
                font-family: Source Han Sans CN, Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 30px;
                text-align: center;
                cursor: default;
            }

            span:nth-child(3) {
                cursor: pointer;
                width: 100px;
                height: 30px;
                border-radius: 8px 8px 8px 8px;
                opacity: 1;
                border: 1px solid #444444;
                font-size: 14px;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 30px;
                text-align: center;
            }
        }

        .right {
            width: 200px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: space-around;

            span {
                color: #fff;
                font-weight: 500;
                width: 87px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                border-radius: 8px;
                border: 1px solid #707070;
                cursor: pointer;
            }

            .user {
                cursor: pointer;
            }
        }
    }

    @media (max-width: 1200px) {
        .content {
            width: 800px;
            /* 根据需要调整其他样式以适应较小的屏幕 */
        }
    }

    @media (max-width: 800px) {
        .content {
            width: 100%;
            padding: 0 10px;
        }


    }
}
</style>
<style lang="less">
.main-top-user-feedback-popover {
    width: 160px;

    .user {
        cursor: pointer;
    }

    .ant-popover-inner {
        background-color: #565A77 !important;
        width: 120px;
        height: 100px;
        box-shadow: 0px 0px 10px 1px #260246;
        border-radius: 8px 8px 8px 8px;

        .ant-popover-inner-content {
            padding: 0;
            padding-top: 6px;

            .my-info {
                width: 116px;
                height: 40px;
                opacity: 1;
                text-align: center;
                font-size: 16px;
                font-weight: 400;
                color: #A8A8A5;
                line-height: 40px;
                margin: 0 auto;
            }

            .login-out {
                width: 116px;
                height: 40px;
                color: #A8A8A5;
                text-align: center;
                font-size: 16px;
                line-height: 40px;
                margin: 0 auto;
                margin-top: 10px;
            }
        }


        .my-info:hover {
            background: linear-gradient(134deg, #16A6E7 0%, #AA49FB 100%);
            border-radius: 8px 8px 8px 8px;
            color: #FFFFFF;
            font-size: 16px;
            line-height: 40px;
        }

        .login-out:hover {
            background: linear-gradient(134deg, #16A6E7 0%, #AA49FB 100%);
            border-radius: 8px 8px 8px 8px;
            color: #FFFFFF;
            font-size: 16px;
            line-height: 40px;
        }


    }
    .ant-popover-content{
        .ant-popover-arrow{
            left: 55px !important;
            .ant-popover-arrow-content{
        background-color: #565A77 !important;
    }
        }
      
    }
    
}
</style>
