import { defineStore } from 'pinia';
import { login as userLogin, logout as userLogout, getUserInfo, LoginData, } from '@/api/user/index';
import { setToken, clearAll, setAuthCache } from '@/utils/auth';
import { UserState } from './types';
export const useUserStore = defineStore('user', {
  state: (): UserState => ({
    id: "",
    vdpUid: '',
    userId: '',
    nickname: "",
    token: "",
    phone: "",
    email: "",
    gender: 0,
    headImg: "",
    userInfo: null,
  }),
  getters: {
    userProfile(state: UserState): UserState {
      return {
        ...state
      };
    },
  },
  actions: {
    // 设置用户的信息
    setInfo(partial: Partial<UserState>) {
      this.$patch(partial);
    },
    // 重置用户信息
    resetInfo() {
      this.$reset();
    },
    // 获取用户信息
    async getInfo() {
      return new Promise(async (resolve) => {
        const result = await getUserInfo();
        await this.setInfo({
          userInfo: result?.data
        });
        setAuthCache('USER__INFO__',JSON.stringify(result?.data) );
        resolve(result);
      });
    },
    

    // 异步登录并存储token
    async login(loginForm: LoginData) {
      const result = await userLogin(loginForm);
      const token = result?.data?.tokenValue;
      await this.setInfo({
        ...result?.data,
        token: result?.data?.tokenValue
      });
      if (token) {
        setToken(token);
        this.getInfo()
      }
      return result;
    },
    // Logout
    async logout() {
      const result = await userLogout();
      this.resetInfo();
      clearAll()
      return result;
    },
  },
});
