import axios from 'axios';
import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError, InternalAxiosRequestConfig } from 'axios';
import { message } from 'ant-design-vue';
import { IResponse } from './type';
import { getToken } from '@/utils/auth';

console.log('import.meta.env.VITE_APP_API_BASEURL', import.meta.env.VITE_API_BASEURL)

const service: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_BASEURL,
  timeout: 3600000,
});

// axios实例拦截请求
service.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = encodeURIComponent(`${token}`)
    }
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  },
);

// axios实例拦截响应
service.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.status === 200) {
      return response;
    }
    return response;
  },
  // 请求失败
  (error: any) => {
    const { response } = error;
    if (response) {
      // 请求已发出，但是不在2xx的范围
      if (response.status == 401) {
        // message.warning('登录失效');
        // 清空token
        setTimeout(() => {
          localStorage.clear();
          window.location.reload()
        }, 1000)
      } else if (response.status == 504) {
        message.error('服务器连接超时!');
      } else {
        message.error(response.data.msg || response.msg || response || '服务器连接超时');
      }
      return Promise.reject(response.data);
    }
    message.error('网络连接异常,请稍后再试!');
  },
);

const request = <T = any>(config: AxiosRequestConfig): Promise<T> => {
  const conf = config;
  return new Promise((resolve, reject) => {
    service.request<any, AxiosResponse<IResponse>>(conf).then((res: AxiosResponse<IResponse>) => {
      if (res) {
        const { data } = res;
        resolve(data as T);
        if (data) {
          resolve(data as T);
        } else {
          reject(res)
        }
      }
    }).catch(error => {
      reject(error)
    })
  });
};

export function get<T = any>(config: AxiosRequestConfig): Promise<T> {
  return request({ ...config, method: 'GET' });
}

export function post<T = any>(config: AxiosRequestConfig): Promise<T> {
  return request({ ...config, method: 'POST' });
}

export function downLoadWord<T = any>(config: AxiosRequestConfig): Promise<T> {
  return request({ ...config, method: 'POST', responseType: 'blob' });
}

//delete请求
export function deleteReq<T = any>(config: AxiosRequestConfig): Promise<T> {
  return request({ ...config, method: 'DELETE' });
}



export default request;

export type { AxiosInstance, AxiosResponse };
