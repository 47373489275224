import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router';
import generatedRoutes from 'virtual:generated-pages';
import { setupLayouts } from 'virtual:generated-layouts'
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { useUserStore } from '@/store';
import { routesWhiteListEnum } from '@/utils/constant'
import {getToken} from '@/utils/auth'
import { useTracking } from 'zsw-event-track';

var routes = setupLayouts(generatedRoutes)
routes.push({
  path: '/',
  redirect: '/home',
  meta:{keepAlive:true}
});
//导入生成的路由数据
const router = createRouter({
  history: createWebHistory('/bim-community'),
  routes,
});


router.beforeEach(async (_to, _from, next) => {
  NProgress.start();
  //获取浏览器本地缓存的token
  const userStore = useUserStore();
  const hasToken = getToken();
  const {userInfo}:any = storeToRefs(useUserStore());
  // 如果存在token 直接放行
  if (hasToken &&  !userInfo.value?.userId) {
    await userStore.getInfo()
  } 
  next()
});

router.afterEach((_to, _from) => {
  NProgress.done();
  setTimeout(() => {
    const {track, setGlobalContext } = useTracking();
    const {userInfo} = storeToRefs(useUserStore());
    setGlobalContext('userId',userInfo?.value.userId )
    track({
      eventCode: 'page_view',
      eventName: '页面浏览',
      eventType: 'exposure',
      eventData: {
        from_page: `/bim-community${_from.path}`,
        page_url:  `/bim-community${_to.path}`,
        page_name: `/bim-community/${String(_to.name)}`,
      } 
    })
  }, 500);
});

export default router;
